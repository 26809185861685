import { Typography } from "@material-ui/core"
import * as React from "react"
import { useState, useEffect } from "react"
import { HiOutlineDocumentDownload } from "react-icons/hi"
import PdfRender from "../components/DocumentsModule/PdfRender/PdfRender"
import { useStaticQuery, graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import {
  Layout, PageHeader,
  PageNavigator,
  SectionContainer
} from "../components/_layout"

import { HEIGHT } from "../components/_layout/Layout/Layout"

const getDocumentsInfo = graphql`
    query {
        documentCategoryData: allContentfulDocumentosCategoria(
            sort: { fields: [orden], order: ASC }
        ) {
            edges {
                node {
                    id
                    nombreDeLaCategoria
                    documentos___elemento {
                        id
                        titulo
                        ordenDocumento
                        categoria {
                            id
                            nombreDeLaCategoria
                        }
                        archivo {
                            file {
                                url
                                fileName
                                contentType
                                details {
                                    size
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

interface IDocumentItemContentful {
  id: string
  titulo: string
  ordenDocumento: number
  archivo: {
    file: {
      url: string
      fileName: string
      contentType: string
      details: {
        size: number
      }
    }
  }
}

export interface IDocumentItem {
  title: string
  url: string
  filename: string
  type: string
  fileSize: number
}

export interface IDocumentCategory {
  id: string
  title: string
  data: IDocumentItem[]
}

const Documentos: React.FC = () => {
  const [currentSelection, setCurrentSelection] = useState<IDocumentItem>(null)
  const [currentCategory, setCurrentCategory] = useState<IDocumentCategory>(
    null
  )
  const { documentCategoryData } = useStaticQuery(getDocumentsInfo)

  const documentsByCategory: IDocumentCategory[] = documentCategoryData.edges.map(
    ({ node }: any) => ({
      id: node.id,
      title: node.nombreDeLaCategoria,
      data: node.documentos___elemento
        ? node.documentos___elemento.map(
          (e: IDocumentItemContentful, i: number) => ({
            id: e.id,
            title: e.titulo,
            orden: e.ordenDocumento,
            url: e.archivo.file.url,
            filename: e.archivo.file.fileName,
            type: e.archivo.file.contentType.split("/")[1],
            fileSize: e.archivo.file.details.size
          })
        ).sort((a, b) => (a.orden > b.orden) ? 1 : -1)
        : []
    })
  )

  const breakpoint = useBreakpoint()

  useEffect(() => {
    if (!currentSelection && documentsByCategory.length > 0) {
      setCurrentSelection(documentsByCategory[0].data[0])
      setCurrentCategory(documentsByCategory[0])
    }
  }, [documentsByCategory])

  return (
    <Layout
      withNav
      seoTitle="Documentos"
      seoDescription="Explora y descarga los documentos de la PNIF"
    >
      <SectionContainer fullHeight sectionID="document-navigator">
        <PageHeader
          title={ "Explora los documentos de la PNIF" }
          icon={ <HiOutlineDocumentDownload /> }
        />
        <PageNavigator
          instructions={ "Selecciona el documento que quieras consultar o descargar" }
          titleStrong="Descarga"
          title="los documentos de la PNIF"
          // @ts-ignore
          data={ documentsByCategory }
          loading={ false }
          loadingText="Cargando documentos"
          scrollOffset={ HEIGHT * 2 }
          mobileAction={ () =>
            typeof window !== "undefined" && window.open(currentSelection.url)
          }
          { ...{ currentSelection, setCurrentSelection, setCurrentCategory } }
        >
          { !breakpoint.sm && currentSelection && (
            <PdfRender { ...currentSelection } />
          ) }
        </PageNavigator>
      </SectionContainer>
    </Layout>
  )
}

export default Documentos
