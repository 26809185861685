import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"

interface IStyleProps {
}

export default makeStyles((theme: ITheme) => ({
  root: {
    backgroundColor: theme.palette.background.paperLight,
    padding: theme.spacing(4),
    [theme.breakpoints.up("md")]: {}
  },

  pagecContainer: {
    flexDirection: "column"
  },

  pagec: {},

  buttonc: {
    margin: theme.spacing(2, 0),
    width: "100%",
    "& div": {
      marginRight: theme.spacing(1)
    }
  },

  documentContainer: {
    flexDirection: "column"
  },

  button: {
    padding: theme.spacing(0.12),
    backgroundColor: theme.palette.extras.yellow,
    fontSize: theme.typography.body2.fontSize,
    textTransform: "none",
    width: "7rem",

    "& span": {
      color: theme.palette.text.primary,
      fontWeight: "bold",
      fontSize: theme.typography.body2.fontSize
    },

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.lightText
    },

    [theme.breakpoints.up("md")]: {}
  },

  disabledButton: {
    backgroundColor: theme.palette.text.lightMild,

    "& span": {
      color: theme.palette.text.lightText,
      fontWeight: theme.typography.fontWeightRegular
    }
  },

  document: {
    minHeight: 350,
  },

  pdfRenderer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },

  titleContainer: {
    justifyContent: "center",
    alignItems: "center",

    "& h6": {
      fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: theme.spacing(2)
    }
  },

  informationContainer: {
    padding: theme.spacing(0, 4)
  },

  informationItem: {
    margin: theme.spacing(1, 0),
    "& strong": {
      textTransform: "uppercase"
    }
  },

  downloadLink: {
    fontSize: theme.typography.body2.fontSize,
    margin: theme.spacing(2, 0, 3, 0),
    color: theme.palette.text.primary,
    textTransform: "none"
  },

  subtitle: {

    margin: theme.spacing(2, 0, 1, 0),
    "& h6": {
      fontWeight: theme.typography.fontWeightBold
    }
  }
}))
