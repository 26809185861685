import { Button, Grid, Typography } from "@material-ui/core"
import * as React from "react"
import { FC, useEffect, useRef, useState } from "react"
import { bytesToSize } from "../../../helpers/helperFunctions"
import { IDocumentItem } from "../../../pages/documentos"
import useStyles from "./styles"


let PDF

if (typeof window !== "undefined") {
  PDF = require("react-pdf")
}

interface IPdfRender extends IDocumentItem {
}

const PdfRender: FC<IPdfRender> = ({ title, url, type, fileSize }) => {
  const styles = useStyles()
  const documentContainerRef = useRef(null)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (
      documentContainerRef.current &&
      documentContainerRef.current.getBoundingClientRect().width
    ) {
      setWidth(documentContainerRef.current.getBoundingClientRect().width)
    }
    if (
      documentContainerRef.current &&
      documentContainerRef.current.getBoundingClientRect().height
    ) {
      setHeight(documentContainerRef.current.getBoundingClientRect().height)
    }
  }, [])

  if (PDF) {
    const { pdfjs } = PDF
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${ pdfjs.version }/pdf.worker.js`
  }

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", event => {
    event.preventDefault()
  })

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    setPageNumber(1)
  }

  const changePage = offset => {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  const previousPage = () => {
    changePage(-1)
  }

  const nextPage = () => {
    changePage(1)
  }

  if (!PDF) {
    return null
  }

  return (
    <Grid container item className={ styles.root }>
      <Grid container item xs direction={ "column" } className={ styles.informationContainer }>


        <Grid item className={ styles.titleContainer }>
          <Typography variant="h6">{ title }</Typography>
        </Grid>

        <Grid item className={ styles.informationItem }>
          <Typography variant="body2" component="span">
            Formato: <strong>{ type }</strong>
          </Typography>
        </Grid>


        <Grid item className={ styles.informationItem }>
          <Typography variant="body2" component="span">
            Tamaño: <strong> { bytesToSize(fileSize) }</strong>
          </Typography>
        </Grid>

        <Grid item className={ styles.informationItem }>
          <Grid item container className={ styles.pagecContainer }>
            <Grid className={ styles.pagec }>
              Página { pageNumber || (numPages ? 1 : "--") } de { numPages || "--" }
            </Grid>
            <Grid container item xs className={ styles.buttonc }>
              <Grid item>
                <Button
                  disabled={ pageNumber <= 1 }
                  aria-label="Anterior"
                  onClick={ previousPage }
                  className={
                    pageNumber <= 1
                      ? `${ styles.button } ${ styles.disabledButton }`
                      : styles.button
                  }
                >
                  Anterior
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={ pageNumber >= numPages }
                  aria-label="Siguiente"
                  onClick={ nextPage }
                  className={
                    pageNumber >= numPages
                      ? `${ styles.button } ${ styles.disabledButton }`
                      : styles.button
                  }
                >
                  Siguiente
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>


        <Grid item>
          <Button
            variant="outlined"
            aria-label="Descargar documento"
            onClick={ () =>
              typeof window !== "undefined" && window.open(`https://${ url }`)
            }
            className={ styles.downloadLink }
          >
            Descargar documento
          </Button>
        </Grid>
      </Grid>


      <Grid container item xs={ 6 } className={ styles.documentContainer }>
        <Grid
          container
          item
          ref={ documentContainerRef }
          className={ styles.document }
        >
          <PDF.Document
            file={ `https://${ url }` }
            onLoadSuccess={ onDocumentLoadSuccess }
            loading="Cargando PDF..."
            error="Lo sentimos, ha ocurrido un error."
            noData="Lo sentimos, no se ha encontrado el documento."
            className={ styles.pdfRenderer }
          >
            <PDF.Page
              pageNumber={ pageNumber }
              width={ width }
            />
          </PDF.Document>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PdfRender
